const projectReducer = (state, action) => {
    switch (action.type) {
        case 'GET_PROJECTS': {
            return {
                ...state,
                projects: [...action.payload],
                error: {enabled: false, message: ""},
                values: action.values
            }
        }
        case 'GET_PROJECT_BY_KEY': {
            return {
                ...state,
                project: {...action.payload},
                project_collaborators: [...action.payload.collaborators],
                error: {enabled: false, message: ""},
                values: action.values
            }
        }
        case 'GET_PROJECTS_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'GET_PROJECTS_BY_COLLABORATOR': {
            return {
                ...state,
                projects_by_collaborator: [...action.payload],
                error: {enabled: false, message: ""},
                values: action.values
            }
        }
        case 'GET_PROJECTS_BY_OPERATIONAL_UNIT': {
            return {
                ...state,
                projects_by_collaborator: {[action.key]: [...action.payload]},
                error: {enabled: false, message: ""},
                values: action.values
            }
        }
        case 'GET_PROJECTS_BY_CUSTOMER': {
            return {
                ...state,
                projects: [...action.payload],
                error: {enabled: false, message: ""},
                values: action.values
            }
        }
        case 'SAVE_PROJECT': {
            return {
                ...state,
                project: {...action.payload},
                projects: [...state?.projects, action.payload],
                error: {enabled: false, message: ""}
            }
        }
        case 'UPDATE_PROJECT': {
            return {...state, project: {...action.payload}, error: {enabled: false, message: ""}}
        }
        case 'SAVE_PROJECTS_BY_COLLABORATOR': {
            return {
                ...state,
                projects_by_collaborator: action.payload,
                error: {enabled: false, message: ""}
            };
        }
        case 'UPDATE_COLLABORATOR_IN_PROJECT': {
            return {
                ...state,
                projects_by_collaborator: [...action.payload],
                values: action.values,
                error: {enabled: false, message: ""}
            };
        }
        case 'REMOVE_SCHEDULED_PROJECT': {
            return {
                ...state,
                projects_by_collaborator: [...action.payload],
                values: action.values,
                error: {enabled: false, message: ""}
            };
        }
        case 'SAVE_TEAM_PROJECT': {
            return {
                ...state,
                project: {...action.payload},
                values: action.values,
                error: {enabled: false, message: ""}
            }
        }
        case 'UNASSIGN_COLLABORATOR_TO_PROJECT': {
            return {
                ...state,
                projects_by_collaborator: [...action.payload],
                values: action.values,
                error: {enabled: false, message: ""}
            }
        }
        case 'GET_PROJECTS_BY_COLLABORATOR_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'ARCHIVE_PROJECT': {
            return {
                ...state,
                projects: [...action.payload],
                error: {enabled: false, message: ""}
            }
        }
        case 'GET_ARCHIVED_PROJECTS': {
            return {
                ...state,
                projects: [...action.payload],
                error: {enabled: false, message: ""}
            }
        }
        case 'ARCHIVE_PROJECT_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }

        case 'GET_PROFILES_COLLABORATORS_BY_PROJECT_KEY': {
            return {
                ...state,
                profiles_collaborators: [...action.payload],
                error: {enabled: false, message: ""}
            }
        }
        case 'ADD_PROFILE_TO_PROJECT' : {
            return {
                ...state,
                profiles_collaborators: [...action.payload],
                error: {enabled: false, message: ""}
            }
        }

        case 'GET_NEW_BUSINESS_BY_ACCOUNT_ID' : {
            return {
                ...state,
                new_business: [...action.payload],
                error: {enabled: false, message: ""}
            }
        }
        case 'UPDATE_PROFILE_IN_PROJECT' : {
            return {
                ...state,
                profiles_collaborators: [...action.payload],
                error: {enabled: false, message: ""}
            }
        }
        case 'GET_PROJECT_PROFILES' : {
            return {
                ...state,
                profiles_collaborators: [...action.payload],
                error: {enabled: false, message: ""}
            }
        }
        case 'SAVE_NEW_BUSINESS_BILLING' : {
            return {
                ...state,
                project: {...action.payload},
                error: {enabled: false, message: ""}
            }
        }
        case 'PROJECT_ERROR': {
            return {...state, error: {enabled: true, message: action.payload}}
        }
        case 'SET_LOADING': {
            return {...state, loading: action.payload}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

export default projectReducer;
